import React, { useEffect } from "react";
import classes from "./dashboard.module.scss";
import { getDashboardRequest } from "../../../store/supervisor/actions";
import {
  makeSelectDashboard,
  makeSelectLoading,
} from "../../../store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import RecentActivity from "./RecentActivity";
import SectionStats from "./SectionStats";
import OverviewStats from "./OverviewStats";
import ReactLoading from "react-loading";

const Dashboard = ({ dashboardData, getDashboard, loading }) => {
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <>
      <div className={classes.dashboardContainer}>
        <h1
          style={{
            padding: "30px",
            fontSize: "Open Sans",
            fontWeight: "bold",
          }}
        >
          Dashboard
        </h1>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <>
            <OverviewStats dashboardData={dashboardData} />
            <h4 className={classes.midTitle}>By Section:</h4>
            <div className={classes.dashboardWrapper}>
              <SectionStats dashboardData={dashboardData} />
              <RecentActivity dashboardData={dashboardData} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  dashboardData: makeSelectDashboard(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: (val) => dispatch(getDashboardRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
