import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSupervisor = (state) => state.supervisor || initialState;

const makeSelectContractorList = () =>
  createSelector(selectSupervisor, (state) => state.contractorList);

const makeSelectContractorStats = () =>
  createSelector(selectSupervisor, (state) => state.contractorStats);

const makeSelectDashboard = () =>
  createSelector(selectSupervisor, (state) => state.dashboard);

const makeSelectLoading = () =>
  createSelector(selectSupervisor, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectSupervisor, (state) => state.error);

export {
  selectSupervisor,
  makeSelectLoading,
  makeSelectError,
  makeSelectContractorList,
  makeSelectContractorStats,
  makeSelectDashboard,
};
