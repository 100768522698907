import React from "react";
import classes from "./itemCheckbox.module.scss";
import CorrectIcon from "../../assets/icons/correct.svg";
const ItemCheckBox = (props) => {
  return (
    <div className={classes.ItemCheckboxWrapper}>
      <div onClick={props.onClick} className={classes.ItemCard}>
        <span className={classes.ItemText}>{props.title}</span>
      </div>
      <div className={classes.Checkbox} onClick={props.onClick}>
        {props.checked && (
          <img
            alt="Correct Icon"
            className={classes.CorrectIconImage}
            src={CorrectIcon}
          />
        )}
      </div>
    </div>
  );
};

export default ItemCheckBox;
