export const sections = {
  0: "Induction",
  1: "Welcome",
  2: "Take a look around",
  3: "Drugs & Alcohol",
  4: "Exclusion zones",
  5: "Speed limits",
  6: "Scaffold",
  7: "Falls",
  8: "Dust",
  9: "Card System",
  10: "Site Rules",
  11: "Induction Complete",
};

export const assesment = {
  3: "Drugs & Alcohol",
  4: "Exclusion zones",
  5: "Speed limits",
  8: "Falls",
  6: "Scaffold",
  7: "Dust",
};
