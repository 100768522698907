import React from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { supervisorLogout } from "../../store/auth/actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const Avatar = process.env.PUBLIC_URL + "/avatars/user.svg";

const TheHeaderDropdown = ({ logout }) => {
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={Avatar}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          onClick={() => {
            logout();
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = (dispatch) => {
  return {
    logout: (val) => dispatch(supervisorLogout(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdown);
