import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//action start
import { loginRequest } from "../../store/auth/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "../../store/auth/selector";
//selectors end

//components start
import { Formik, Field, Form } from "formik";
import Button from "../../components/button";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Input from "../../components/input";
//components end

import classes from "./auth.module.scss";
import LoginSchema from "./validationSchema";
import Blur from "react-css-blur";

const Auth = (props) => {
  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  }, []);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  const initialValues = {
    email: email,
    lastName: lastName,
    firstName: firstName,
    company: company,
  };

  const BacgroundImage = process.env.PUBLIC_URL + "/loginbg/login.jpg";

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${BacgroundImage})`,
          backgroundPosition: "center center",
          position: "absolute",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          filter: "blur(40px)",
          right: 0,
          top: 0,
        }}
      />
      <Header transparent />

      <Formik
        validationSchema={LoginSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          props.onSubmitForm(values);
        }}
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className={classes.FormWrapper}>
                <Field
                  error={errors && errors.firstName && true}
                  placeholder={"Tap to enter your First Name"}
                  label={"First Name"}
                  name="firstName"
                  value={values.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  component={Input}
                />
                <Field
                  error={errors && errors.lastName && errors.lastName.length}
                  placeholder={"Tap to enter your Last Name"}
                  label={"Last Name"}
                  name="lastName"
                  value={values.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  component={Input}
                />
                <Field
                  error={errors && errors.email && errors.email.length}
                  placeholder={"Tap to enter your Email"}
                  label={"Email"}
                  name="email"
                  value={values.email}
                  onChange={(e) => setEmail(e.target.value)}
                  component={Input}
                />
                <Field
                  error={errors && errors.company && errors.company.length}
                  placeholder={"Tap to enter your Company"}
                  label={"Company Name"}
                  name="company"
                  value={values.company}
                  onChange={(e) => setCompany(e.target.value)}
                  component={Input}
                />
              </div>

              <Footer transparent>
                <Button
                  loading={props.loading}
                  id="apply-button"
                  text="Get Started"
                  type="submit"
                />
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(loginRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
