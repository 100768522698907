/*  SET PROGRESS RESULT */
export const SET_PROGRESS_REQUEST = "PROGRESS/SET_PROGRESS_REQUEST";
export const SET_PROGRESS_REQUEST_SUCCESS =
  "PROGRESS/SET_PROGRESS_REQUEST_SUCCESS";
export const SET_PROGRESS_REQUEST_ERROR = "PROGRESS/SET_PROGRESS_REQUEST_ERROR";

/*  GET PROGRESS RESULT */
export const GET_PROGRESS_REQUEST = "PROGRESS/GET_PROGRESS_REQUEST";
export const GET_PROGRESS_REQUEST_SUCCESS =
  "PROGRESS/GET_PROGRESS_REQUEST_SUCCESS";
export const GET_PROGRESS_REQUEST_ERROR = "PROGRESS/GET_PROGRESS_REQUEST_ERROR";
export const RESET_PROGRESS_REQUEST = "PROGRESS/RESET_PROGRESS_REQUEST";
