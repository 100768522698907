import classes from "./welcome.module.scss";
import React, { useEffect } from "react";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import VideoPlayer from "../../components/videoPlayer";

const Welcome = (props) => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }

    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData && props.progressData.section === 0) {
      props.setProgress({ section: 1, progress: 0 });
    }
  }, [props.progressData]);

  return (
    <div className={classes.Container}>
      <div className={classes.TextContainer}>
        <span className={classes.Heading}>Welcome</span>
        <span className={classes.Description}>
          Welcome to Fletcher Living.
          <br /> Your safety is important to us.
        </span>
      </div>
      <div style={{ width: "100%", marginBottom: "80px" }}>
        <VideoPlayer />
      </div>
      <Footer>
        <Link
          className={classes.Link}
          to={isIE ? "/induction" : "/take-a-look"}
        >
          <Button id="apply-button" text="Get Started" type="submit" />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
