// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
  GET_CONTRACTOR_LIST_REQUEST_ERROR,
  GET_CONTRACTOR_LIST_REQUEST,
} from "../constants";
import axios from "../../../services/api";

function* getContractorListRequest(data) {
  try {
    const response = yield call(
      axios.get,

      data.sort_type != undefined
        ? `/users/?search=${data.searchItem}&page=${data.page}&sort=${data.sort}&sort_type=${data.sort_type}`
        : data.sort != undefined
        ? `/users/?search=${data.searchItem}&page=${data.page}&sort=${data.sort}`
        : `/users/?search=${data.searchItem}&page=${data.page}`
    );
    if (response.data.error) {
      yield put({
        type: GET_CONTRACTOR_LIST_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_LIST_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getContractorListRequestSaga() {
  yield takeLatest(GET_CONTRACTOR_LIST_REQUEST, getContractorListRequest);
}
