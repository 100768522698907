/* AUTH */
export const LOGIN_REQUEST = "AUTH/LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "AUTH/LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "AUTH/LOGIN_REQUEST_ERROR";

export const SUPERVISOR_LOGOUT = "AUTH/SUPERVISOR_LOGOUT";

export const SUPERVISOR_LOGIN_REQUEST = "AUTH/SUPERVISOR_LOGIN_REQUEST";
export const SUPERVISOR_LOGIN_REQUEST_SUCCESS =
  "AUTH/SUPERVISOR_LOGIN_REQUEST_SUCCESS";
export const SUPERVISOR_LOGIN_REQUEST_ERROR =
  "AUTH/SUPERVISOR_LOGIN_REQUEST_ERROR";

export const POST_LOGIN_REQUEST = "AUTH/POST_LOGIN_REQUEST";
export const POST_LOGIN_REQUEST_SUCCESS = "AUTH/POST_LOGIN_REQUEST_SUCCESS";
export const POST_LOGIN_REQUEST_FAIL = "AUTH/POST_LOGIN_REQUEST_FAIL";
export const POST_LOGIN_REQUEST_ERROR = "AUTH/POST_LOGIN_REQUEST_ERROR";

export const RESET_ERROR = "AUTH/RESET_ERROR";
export const REMOVE_TOKEN = "AUTH/REMOVE_TOKEN";
