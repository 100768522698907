import React from "react";
import TimeIcon from "../assets/time.svg";
import ContractorIcon from "../assets/contractorIcon.svg";
import OkeyIcon from "../assets/okey.svg";
import classes from "./overview.module.scss";

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
  var mDisplay =
    m > 0 && m < 10 ? `0${m}` + ":" : m > 0 ? m + (m == 1 ? ":" : ":") : "";
  var sDisplay =
    s > 0 && s < 10 ? `0${s}` + "" : s > 0 ? s + (s == 1 ? "" : "") : "";
  return hDisplay + mDisplay + sDisplay;
}
const OverviewStats = ({ dashboardData }) => {
  return (
    <>
      <h4 className={classes.midTitle}>Overview</h4>
      <div className={classes.OverviewContainer}>
        <div className={classes.OverviewCard}>
          <img className={classes.cardIcon} src={ContractorIcon}></img>
          <span className={classes.cardData}>
            {dashboardData?.total_contractors}
          </span>
          <span className={classes.cardDesc}>Contractors Registered</span>
          <div className={classes.cardProgressContainer}>
            <div className={classes.cardProgress}></div>
          </div>
        </div>
        <div className={classes.OverviewCard}>
          <img className={classes.cardIcon} src={OkeyIcon}></img>
          <span className={classes.cardData}>
            {dashboardData?.total_completed_induction}
          </span>
          <span className={classes.cardDesc}>Inductions Completed</span>
          <div className={classes.cardProgressContainer}>
            <div className={classes.cardProgress}></div>
          </div>
        </div>
        <div className={classes.OverviewCard}>
          <img className={classes.cardIcon} src={TimeIcon}></img>
          <span className={classes.cardData}>
            {secondsToHms(dashboardData?.average_time_to_completion)}
          </span>
          <span className={classes.cardDesc} style={{ marginBottom: "0px" }}>
            Avg. Time to complete <br /> online H&S Induction
          </span>
          <div
            className={classes.cardProgressContainer}
            style={{ marginTop: "20px" }}
          >
            <div
              style={{ backgroundColor: "#20a8d8", width: "70%" }}
              className={classes.cardProgress}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewStats;
