import React, { useEffect } from "react";
import classes from "./siterules.module.scss";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";

import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const SiteRules = ({ getProgress, setProgress, progressData }) => {
  useEffect(() => {
    getProgress();
  }, []);

  useEffect(() => {
    if (progressData.section < 10) {
      setProgress({ section: 10, progress: 0 });
    }
  }, [progressData]);
  return (
    <div className={classes.SiteRuleContainer}>
      <span className={classes.Title}>Site Rules</span>
      <div className={classes.ImageContainer}>
        <div
          className={classes.Image}
          style={{
            backgroundImage:
              "url(https://hs.immersivetechnology.nz/siterules.png)",
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          margin: "20px auto 100px auto",

          paddingBottom: "50px",
        }}
      >
        <span className={classes.Content}>
          Below are a few other rules that apply to all our sites. Remember: If
          in doubt – ask the <br /> Supervisor.
        </span>
        <ul className={classes.List}>
          <li className={classes.Item}>
            Know when your site opens and closes. Each site is different.
          </li>
          <li className={classes.Item}>
            Always sign in and out. Wifi is available if you want to use a
            Fletcher Living QR code.
          </li>
          <li className={classes.Item}>
            No children or pets. Only smoke or vape in designated areas.
          </li>
          <li className={classes.Item}>
            Report all hazards, injuries and near misses to your supervisor as
            soon as possible.
          </li>
          <li className={classes.Item}>
            First Aid Kits and Fire Extinguishers are at the site office.
          </li>
          <li className={classes.Item}>Keep your work area tidy.</li>
          <li className={classes.Item}>
            Power tools must be tested and tagged. They must be used by
            competent people and safeguards must be in place.
          </li>
          <li className={classes.Item}>
            Keep noise levels down. We have neighbours, so please do not set
            your radio at full blast.
          </li>
        </ul>
      </div>
      <Footer>
        <Link className={classes.Link} to="/complete">
          <Button id="apply-button" text="Next" type="submit" />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteRules);
