import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

const bindMiddleware = (middleware) => {
  return composeWithDevTools(applyMiddleware(...middleware));
};
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router"],
};

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistReducer(persistConfig, rootReducer(history)),
  bindMiddleware([sagaMiddleware, routerMiddleware(history)])
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor, history };
