import React, { useState, useEffect, useRef } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CProgress,
  CPopover,
} from "@coreui/react";

import { connect } from "react-redux";
//action start
import {
  getContactorListRequest,
  getContractorStats,
} from "../../../store/supervisor/actions";
//action end

import { usePrevious } from "../../../utils/hooks/index";
import SortIcon from "./sort.svg";
//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectContractorList,
  makeSelectError,
  makeSelectLoading,
} from "../../../store/supervisor/selector";
import { makeSelectSupervisor } from "../../../store/auth/selector";
import Modal from "../ContractorStats";
import Select from "react-select";
import Attention from "../ContractorStats/attention.svg";
import UserIcon from "./userIcon.svg";
import classes from "./contractorList.module.scss";
import CIcon from "@coreui/icons-react";
import { sections } from "../../../utils/enums";
const ContractorList = ({
  contractors,
  getContractorList,
  supervisor,
  getContactorStatistics,
}) => {
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const currentPage = 1;
  const [totalPage, setTotalPage] = useState();
  const [contractorData, setContractorData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedSortType, setSelectedSortType] = useState("");

  const sortOptions = [
    { value: "name", label: "Name" },
    { value: "email", label: "Email" },
    { value: "date_joined", label: "Date Joined" },
    { value: "company", label: "Company" },
    { value: "progress", label: "Progress" },
  ];

  const columns = [
    {
      name: "First Name",
      value: "name",
      key: "first_name",
      _classes: "font-weight-bold",
      sorter: true,
    },
    { name: "Last Name", key: "last_name", value: "last_name", sorter: false },
    { name: "Email", value: "email", key: "email", sorter: true },
    { name: "Company", key: "company", value: "company", sorter: true },
    { name: "Progress", key: "progress", value: "progress", sorter: true },
    { name: "Sites", key: "sites", value: "sites", sorter: false },
    { name: "Completed", key: "completed", value: "completed", sorter: false },
    { name: "Joined", key: "joined", value: "date_joined", sorter: true },
  ];

  const [sortState, setSortState] = useState({
    name: false,
    company: false,
    email: false,
    progress: false,
    joined: false,
  });
  const [sortStatus, setSortStatus] = useState({ type: "", value: "asc" });
  const pageChange = (newPage) => {
    getContractorList({
      page: newPage,
      searchItem: searchItem,
      sort: selectedSort,
      sort_type: selectedSortType,
    });
    setPage(newPage);
  };

  useEffect(() => {
    getContractorList({ page: 1, searchItem: "" });
  }, []);

  const prevContractors = usePrevious(contractors.data);

  useEffect(() => {
    if (prevContractors != contractors.data) {
      setContractorData(contractors.data);
      setTotalPage(contractors.number_of_pages);
    }
  });

  useEffect(() => {
    setContractorData(contractors.data);
    setTotalPage(contractors.number_of_pages);
  }, [contractors.data]);

  return (
    <>
      <CRow>
        <CCol>
          <CCard
            style={{
              width: "95%",
              margin: "20px auto",
              minHeight: "70vh",
              height: "100%",
              boxShadow: "0px 2px 11px 0px rgb(0 0 0 / 10%)",
              border: "none",
            }}
          >
            <h1
              style={{
                margin: "30px",
                fontSize: "Open Sans",
                fontWeight: "bold",
              }}
            >
              Contractors
            </h1>
            <CInputGroup>
              <CCol
                style={{
                  marginTop: "10px",
                  padding: "20px",
                  backgroundColor: "#f0f3f5",
                }}
                md="12"
              >
                <CInputGroup style={{ alignItems: "center" }}>
                  <CInput
                    onChange={(e) => {
                      getContractorList({
                        page: 1,
                        searchItem: e.target.value,
                      });
                      setSearchItem(e.target.value);
                      setPage(1);
                    }}
                    id="input3-group2"
                    name="input3-group2"
                    placeholder="Search"
                  />
                  <CInputGroupPrepend>
                    <CButton
                      type="button"
                      color="info"
                      style={{ marginRight: "20px", display: "flex" }}
                    >
                      <CIcon name="cil-magnifying-glass" />
                    </CButton>
                  </CInputGroupPrepend>
                  <div style={{ width: "150px" }}>
                    <Select
                      onChange={(val) => {
                        setSelectedSort(val.value);
                        getContractorList({
                          page: 1,
                          searchItem: searchItem,
                          sort: val.value,
                        });
                      }}
                      tabSelectsValue={selectedSort}
                      value={sortOptions.filter(
                        (option) => option.value === selectedSort
                      )}
                      placeholder={"Sort by"}
                      options={sortOptions}
                    />
                  </div>
                </CInputGroup>
              </CCol>
            </CInputGroup>
            <CCardBody>
              <div
                className={"position-relative table-responsive"}
                style={{
                  position: "relative",
                  display: "flex",
                  overflowX: "auto",
                }}
              >
                <table className={"table table-striped table-hover"}>
                  <thead>
                    <tr>
                      {columns.map((item) => (
                        <th style={{ cursor: "pointer" }}>
                          <span className={classes.tableHeaderText}>
                            {item.name}
                          </span>
                          {item.sorter && (
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                display:
                                  selectedSort === item.value
                                    ? "initial"
                                    : "none",
                                transform:
                                  sortStatus?.type === item.value &&
                                  sortState === item.value
                                    ? "rotate(0deg)"
                                    : "rotate(-180deg)",
                              }}
                              onClick={() => {
                                setSortStatus({
                                  type: item.value,
                                  value:
                                    sortStatus.value === "asc"
                                      ? "dsc"
                                      : sortStatus.value === "dsc"
                                      ? "asc"
                                      : "dsc",
                                });
                                getContractorList({
                                  page: 1,
                                  searchItem: searchItem,
                                  sort: item.value,
                                  sort_type:
                                    sortState === item.value ? "asc" : "dsc",
                                });
                                setSelectedSortType(
                                  sortState === item.value ? "dsc" : "asc"
                                );
                                setSelectedSort(item.value);
                                if (sortState === item.value) {
                                  setSortState(false);
                                } else {
                                  setSortState(item.value);
                                }
                              }}
                              src={SortIcon}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={classes.tableBody}>
                    {contractorData?.map((item) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModal({ status: true, data: item });
                          getContactorStatistics({ email: item.email });
                        }}
                      >
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={UserIcon}
                              style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                              }}
                            />
                            <span> {item.first_name}</span>
                          </div>
                        </td>
                        <td>{item.last_name}</td>
                        <td>
                          {item?.email?.length > 15
                            ? `${item?.email?.split("@")[0]}@...`
                            : item?.email}
                        </td>
                        <td>{item.company}</td>
                        <td>
                          <div
                            className={classes.tooltip}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "140px",
                            }}
                          >
                            <span className={classes.tooltiptext}>
                              {sections[item.section]}
                            </span>
                            <CProgress
                              style={{ width: "110px" }}
                              className="progress-xs"
                              color="success"
                              value={(item.section * 100) / 11}
                            />
                            {item.failed > 0 && (
                              <CPopover
                                header={false}
                                content={`${item.failed} failed attempts.`}
                                placement={"bottom"}
                                interactive={true}
                                trigger="hover"
                              >
                                <img
                                  style={{
                                    marginLeft: "10px",
                                    display: "flex",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  src={Attention}
                                />
                              </CPopover>
                            )}
                          </div>
                        </td>
                        <td>1</td>
                        <td>{item.completion_date?.slice(0, 10)}</td>
                        <td>{item.date_joined?.slice(0, 10)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPage != 1 && (
                <CPagination
                  activePage={page}
                  onActivePageChange={pageChange}
                  pages={totalPage}
                  doubleArrows={true}
                  align="center"
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <Modal setModal={setModal} modal={modal} />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  contractors: makeSelectContractorList(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getContractorList: (val) => dispatch(getContactorListRequest(val)),
    getContactorStatistics: (val) => dispatch(getContractorStats(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorList);
