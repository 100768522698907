import {
  GET_CONTRACTOR_LIST_REQUEST,
  GET_CONTRACTOR_STATS_REQUEST,
  GET_DASHBOARD_REQUEST,
} from "./constants";

export const getContactorListRequest = (payload) => {
  return {
    type: GET_CONTRACTOR_LIST_REQUEST,
    ...payload,
  };
};

export const getContractorStats = (payload) => {
  return {
    type: GET_CONTRACTOR_STATS_REQUEST,
    ...payload,
  };
};

export const getDashboardRequest = (payload) => {
  return {
    type: GET_DASHBOARD_REQUEST,
    ...payload,
  };
};
