import React, { useState } from "react";
import { CHeaderBrand } from "@coreui/react";
import Logo from "../../assets/logo/logo.svg";
import MenuIcon from "./menuIcon";
import classes from "./header.module.scss";
import DrawerNavigation from "./drawerNavigation";
import { withRouter, Link } from "react-router-dom";
import { Keyframes, animated } from "react-spring/renderprops";
import delay from "delay";
import Blur from "react-css-blur";

const Sidebar = Keyframes.Spring({
  // Slots can take arrays/chains,
  peek: [
    { x: 0, from: { x: 100 }, delay: 500 },
    { x: -100, delay: 800 },
  ],
  // single items,
  open: { delay: 0, x: 0 },
  // or async functions with side-effects
  close: async (call) => {
    await delay(0);
    await call({ delay: 0, x: 110 });
  },
});

//const MainLogo = process.env.PUBLIC_URL + "/logo.png";
const MainLogo = "https://immersivefrl.s3-ap-southeast-2.amazonaws.com/fl_logo_small.png";

const Header = (props) => {
  const state = props.open ? "open" : "close";

  return (
    <div
      style={{ background: props.transparent && "transparent" }}
      className={props.open ? classes.open : classes.headerContainer}
    >
      <Blur radius={props.open ? "5px" : "0"} transition="400ms">
        <Link to="/">
          <img alt={"logo"} className={classes.headerLogo} src={MainLogo} />
        </Link>
      </Blur>
      {props.menu && (
        <React.Fragment>
          <MenuIcon onClick={() => props.setOpen(true)} />
          <Sidebar native state={state}>
            {({ x }) => (
              <animated.div
                className={classes.DrawerContainer}
                style={{
                  transform: x.interpolate((x) => `translate3d(${x}%,0,0)`),
                }}
              >
                <DrawerNavigation
                  show={props.open}
                  action={() => props.setOpen(false)}
                />
              </animated.div>
            )}
          </Sidebar>
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(Header);
