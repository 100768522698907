import React from "react";
import UserIcon from "../assets//user.svg";
import EmailIcon from "../assets/email.svg";
import CorrectIcon from "../assets/correctIcon.svg";
import AttentionIcon from "../assets/attention.svg";
import classes from "../dashboard.module.scss";
const RecentActivity = ({ dashboardData }) => {
  return (
    <div className={classes.dashboardSection2}>
      <div className={classes.recentActivityHeaderContainer}>
        <span>Recent Activity</span>
      </div>
      <div
        className={classes.recentActivityContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {dashboardData?.recent_activity?.length === 0 ? (
          <span style={{ padding: "10px" }}>There is no recent activity</span>
        ) : (
          dashboardData?.recent_activity.map((item) => (
            <div className={classes.recentActivityItemWrapper}>
              <div className={classes.itemContentWrapper}>
                <img className={classes.userIcon} src={UserIcon} />
                <div className={classes.recentActivityItemDescriptionWrapper}>
                  <span className={classes.itemUserName}>{item.user}</span>
                  <span className={classes.emailText}>
                    <img className={classes.emailIcon} src={EmailIcon} />
                    {item?.email?.length > 20
                      ? `${item?.email?.split("@")[0]}@...`
                      : item?.email}
                  </span>
                </div>
              </div>
              <div className={classes.itemStatusWrapper}>
                <div className={classes.itemDay}>
                  <span>
                    <i className={classes.itemDaysPassedItem}>
                      {item.days_passed}
                    </i>
                  </span>
                </div>
                <div className={classes.itemStatus}>
                  <img
                    style={{
                      width: item.passed === 0 && "32px",
                      height: item.passed === 0 && "32px",
                    }}
                    src={item.passed === 1 ? CorrectIcon : AttentionIcon}
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RecentActivity;
