// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SUPERVISOR_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import axios from "../../../services/api";

function* supervisorloginRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("email", data.values.name);
    params.append("password", data.values.password);

    const response = yield call(axios.post, "/supervisor-login/", params);
    if (response.data.error) {
      yield put({
        type: SUPERVISOR_LOGIN_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      const token = response.data.token;
      axios.defaults.headers.common["X-Token"] = `${token}`;
      yield call([localStorage, localStorage.setItem], "AUTH_TOKEN", token);
      yield put({
        type: SUPERVISOR_LOGIN_REQUEST_SUCCESS,
        data: response.data,
      });
      yield put(push("/supervisor-dashboard"));
    }
  } catch (error) {
    yield put({
      type: SUPERVISOR_LOGIN_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* supervisorloginRequestSaga() {
  yield takeLatest(SUPERVISOR_LOGIN_REQUEST, supervisorloginRequest);
}
