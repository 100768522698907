import React, { useEffect } from "react";
import classes from "./step2.module.scss";
import { createMarkup } from "../../../../../utils/helper";
//What Could Happen Type
const Step2 = ({ data }) => {
  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  }, []);
  return (
    <div className={classes.StepContainer}>
      <span className={classes.title}>{data.title}</span>
      <div className={classes.contentWrapper}>
        {data &&
          data.content.map((item, index) => (
            <div key={index}>
              <span className={classes.contentTitle}>{item.title}</span>
              <div
                dangerouslySetInnerHTML={createMarkup(item.text)}
                className={classes.contentText}
              />
            </div>
          ))}
        <div className={classes.ImageContainer}>
          {data &&
            data?.images &&
            data?.images.length != 0 &&
            data?.images
              .slice(0, 2)
              .map((item, index) => (
                <div
                  key={index}
                  style={{ backgroundImage: `url(${item})` }}
                  className={classes.ImageWrapper}
                ></div>
              ))}
        </div>
        {data?.images?.length === 3 && (
          <div
            className={classes.ImageContainer}
            style={{ marginTop: "-35px" }}
          >
            <div
              style={{ backgroundImage: `url(${data.images[2]})` }}
              className={classes.ImageWrapper}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
