import React from "react";
import { Sidebar } from "../../components";
import Header from "../../components/supervisorHeader";
import Footer from "../../components/supervisorFooter";
import { CContainer, CFade } from "@coreui/react";

const Layout = ({ children, menu }) => {
  return (
    <div className="c-app c-default-layout">
      {menu && <Sidebar />}
      <div className="c-wrapper">
        {menu && <Header />}{" "}
        <div className="c-body bg-white">
          <main className="c-main">
            <CContainer fluid>{children}</CContainer>
          </main>
        </div>
        <Footer style={{ zIndex: "99" }} />
      </div>
    </div>
  );
};

export default Layout;
