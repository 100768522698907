import React, { useEffect } from "react";
import classes from "./company.module.scss";
import { getDashboardRequest } from "../../../store/supervisor/actions";
import { makeSelectDashboard } from "../../../store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Chart1 from "./chart1.svg";
import Chart2 from "./chart2.svg";

const Company = ({ dashboardData, getDashboard }) => {
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <>
      <div className={classes.companyContainer}>
        <h1
          style={{
            padding: "30px",
            fontSize: "Open Sans",
            fontWeight: "bold",
          }}
        >
          Company
        </h1>
        <div className={classes.chartContainer}>
          <img className={classes.Chart1} src={Chart1} />
          <img src={Chart2} className={classes.Chart2} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  dashboardData: makeSelectDashboard(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: (val) => dispatch(getDashboardRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
