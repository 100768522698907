import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//action start
import { postLoginRequest } from "../../store/auth/actions";
//action end

//selectors start
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReason,
} from "../../store/auth/selector";
import { createStructuredSelector } from "reselect";
//selectors end

//components start
import Button from "../../components/button";
import Footer from "../../components/footer";
import ItemCheckbox from "../../components/itemCheckbox";
import { Formik, Form, Field } from "formik";
//component end
import Header from "../../components/header";

import classes from "./postLogin.module.scss";

const PostLogin = (props) => {
  const options = [
    { id: 1, title: "I have a current Sitesafe Card", checked: false },
    {
      id: 2,
      title: "I have read & understood my Company Task Analysis",
      checked: false,
    },
  ];
  const [cards, setCards] = useState(options);

  const setCheckBoxHandler = (val) => {
    const newOptions = cards.map((item) => {
      if (item.id === val) {
        return {
          id: item.id,
          title: item.title,
          checked: !item.checked,
        };
      } else {
        return item;
      }
    });
    setCards(newOptions);
  };

  const onSubmit = () => {
    const validator = cards.filter((item) => {
      if (item.checked === true) {
        return null;
      } else {
        return item.id;
      }
    });
    return props.onSubmitForm(validator);
  };

  return (
    <React.Fragment>
      <Formik enableReinitialize initialValues={cards} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Form className={classes.Form}>
              <span className={classes.Heading}>
                Select (or tick) items that apply:
              </span>
              <div className={classes.ItemContainer}>
                {values.map((item) => (
                  <Field
                    value={item.checked}
                    key={item.id}
                    onPress={() => setCheckBoxHandler(item.id)}
                    onClick={() => setCheckBoxHandler(item.id)}
                    title={item.title}
                    checked={item.checked}
                    component={ItemCheckbox}
                  />
                ))}
              </div>
              <Footer>
                <Button id="apply-button" type="submit" text="Next" />
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  reason: makeSelectReason(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(postLoginRequest(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostLogin));
