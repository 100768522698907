import produce, { enableES5 } from "immer";
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_ERROR,
  LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_LOGIN_REQUEST,
  SUPERVISOR_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST,
  POST_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST_FAIL,
  POST_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGOUT,
  RESET_ERROR,
  REMOVE_TOKEN,
} from "./constants";

enableES5();

export const initialState = {
  error: null,
  loading: false,
  user: null,
  supervisor: null,
  supervisorToken: null,
  hasCards: null,
  reason: null,
};

const AuthReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;

      case LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.user = action.data;
        break;

      case LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SUPERVISOR_LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;

      case SUPERVISOR_LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.supervisor = action.data;
        break;

      case SUPERVISOR_LOGOUT:
        draftState.loading = false;
        draftState.error = null;
        draftState.hasCards = null;
        draftState.supervisor = null;
        draftState.user = null;
        break;
      case SUPERVISOR_LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case POST_LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;

      case POST_LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.hasCards = action.data;
        break;

      case POST_LOGIN_REQUEST_FAIL:
        draftState.loading = false;
        draftState.error = null;
        draftState.reason = action.data;
        break;

      case POST_LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
      case RESET_ERROR:
        draftState.loading = false;
        draftState.error = null;
        break;
      case REMOVE_TOKEN:
        draftState.hasCards = null;
        draftState.supervisor = null;
        draftState.user = null;
        break;
      default:
        break;
    }
  });
};

export default AuthReducer;
