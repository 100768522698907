import React, { useEffect } from "react";
import classes from "./step1.module.scss";

//Introduction
const Step1 = ({ data }) => {
  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  }, []);
  return (
    <div className={classes.StepContainer}>
      {data.content.map((item, index) => (
        <span key={index} className={classes.content}>
          {item}
        </span>
      ))}
      <div className={classes.StepImageContainer}>
        {data.images.map((item, index) => (
          <div
            key={index}
            className={classes.StepImageWrapper}
            style={{ backgroundImage: `url(${item})` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Step1;
