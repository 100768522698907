// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST_ERROR,
  POST_LOGIN_REQUEST_FAIL,
} from "../constants";
import { push } from "connected-react-router";

function* postLoginRequest(data) {
  try {
    const items = data.payload;

    if (items.length === 0) {
      const response = { data: { hascards: true } };

      if (response.data.error) {
        yield put({
          type: POST_LOGIN_REQUEST_ERROR,
          data: response.error,
        });
      } else {
        yield put({
          type: POST_LOGIN_REQUEST_SUCCESS,
          data: response.data,
        });
        yield put(push("/post-login/success"));
      }
    } else {
      yield put({
        type: POST_LOGIN_REQUEST_FAIL,
        data: items,
      });
      yield put(push("/post-login/failure"));
    }
  } catch (error) {
    yield put({
      type: POST_LOGIN_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* postLoginRequestSaga() {
  yield takeLatest(POST_LOGIN_REQUEST, postLoginRequest);
}
