import React from "react";
import classes from "../dashboard.module.scss";
import Divider from "../Divider";
import StatsImage from "../assets/stats.svg";
import TimeIcon from "../assets/time.svg";

const Icon1 = process.env.PUBLIC_URL + "/splash/1.png";
const Icon2 = process.env.PUBLIC_URL + "/splash/2.png";
const Icon3 = process.env.PUBLIC_URL + "/splash/3.png";
const Icon4 = process.env.PUBLIC_URL + "/splash/4.png";
const Icon5 = process.env.PUBLIC_URL + "/splash/5.png";
const Icon6 = process.env.PUBLIC_URL + "/splash/6.png";

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (mins === 0 && secs === 0) {
    return "0" + ":" + "01";
  } else {
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
}
const SectionStats = ({ dashboardData }) => {
  return (
    <>
      <div className={classes.dashboardSection1}>
        <div className={classes.progressColumn}>
          <div className={classes.progressRow}>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#158FCE" }}
                    className={classes.progressIcon}
                    src={Icon1}
                  />
                  <p>We always work free of alcohols and drugs.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>

                  <span>
                    {dashboardData?.assesment_stats?.drugs_and_alcohol.toFixed(
                      0
                    )}
                  </span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats
                        ?.drugs_and_alcohol_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#FFC107" }}
                    className={classes.progressIcon}
                    src={Icon5}
                  />
                  <p>We never alter scaffold protection.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>

                  <span>
                    {dashboardData?.assesment_stats?.scaffold.toFixed(0)}
                  </span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats?.scaffold_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div className={classes.progressRow}>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#F86C6B" }}
                    className={classes.progressIcon}
                    src={Icon2}
                  />
                  <p>We always set and obey exclusion zones.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>
                  <span>
                    {dashboardData?.assesment_stats?.exclusion_zones.toFixed(0)}
                  </span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats
                        ?.exclusion_zones_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#4DBD74" }}
                    className={classes.progressIcon}
                    src={Icon4}
                  />
                  <p>We never alter fall protection.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>

                  <span>{dashboardData?.assesment_stats?.fall.toFixed(0)}</span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats?.fall_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div className={classes.progressRow}>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#63C2DE" }}
                    className={classes.progressIcon}
                    src={Icon3}
                  />
                  <p>We always obey the site speed limit.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>

                  <span>
                    {dashboardData?.assesment_stats?.speed_limit.toFixed(0)}
                  </span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats?.speed_limit_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    style={{ borderColor: "#2F353A" }}
                    className={classes.progressIcon}
                    src={Icon6}
                  />
                  <p>We protect everyone from dust and harmful substances.</p>
                </div>
                <Divider />
                <div className={classes.progressItemSection2}>
                  <span className={classes.passRate}>Pass Rate:</span>

                  <span>{dashboardData?.assesment_stats?.dust.toFixed(0)}</span>
                  <p>%</p>
                </div>
              </div>
              <div className={classes.progressSubDescContainer}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TimeIcon}
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Avg time to complete this section{" "}
                  <b>
                    {fancyTimeFormat(
                      dashboardData?.assesment_stats?.dust_average_time
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", height: "300px" }}>
          <h4 className={classes.midTitle}>Last 12 Months :</h4>
          <img
            style={{ width: "100%", height: "250px", margin: "0 auto" }}
            src={StatsImage}
          ></img>
        </div>
      </div>
    </>
  );
};

export default SectionStats;
