// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACTOR_STATS_REQUEST_SUCCESS,
  GET_CONTRACTOR_STATS_REQUEST_ERROR,
  GET_CONTRACTOR_STATS_REQUEST,
} from "../constants";
import axios from "../../../services/api";

function* getContractorListRequest(data) {
  try {
    const params = new URLSearchParams();
    const response = yield call(
      axios.get,
      `/contractor-stats/?email=${data.email}`
    );
    if (response.data.error) {
      yield put({
        type: GET_CONTRACTOR_STATS_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CONTRACTOR_STATS_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_STATS_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getContractorListRequestSaga() {
  yield takeLatest(GET_CONTRACTOR_STATS_REQUEST, getContractorListRequest);
}
