// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUEST_ERROR,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import axios from "../../../services/api";
import { error } from "../../../App.js";
function* loginRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("email", values.email);
    params.append("first_name", values.firstName);
    params.append("last_name", values.lastName);
    params.append("company", values.company);

    const response = yield call(axios.post, "/dummy-login/", params);
    if (response.data.error) {
      yield put({
        type: LOGIN_REQUEST_ERROR,
        data: response.data.error,
      });
      yield call(error, `${response.data.error}`);
    } else {
      const token = response.data.token;
      axios.defaults.headers.common["X-Token"] = `${token}`;
      yield call([localStorage, localStorage.setItem], "AUTH_TOKEN", token);
      yield put({
        type: LOGIN_REQUEST_SUCCESS,
        data: response.data,
      });
      yield put(push("/post-login"));
    }
  } catch (error) {
    yield put({
      type: LOGIN_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* loginRequestSaga() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}
