import React, { useState, useEffect, useCallback } from "react";
import classes from "./step3.module.scss";
import { useSwipeable } from "react-swipeable";
import Carousel from "./Carousel";
import { createMarkup } from "../../../../../utils/helper";
import SwipeIcon from "./swipe.png";
import DoIcon from "./do.png";
import DontIcon from "./dont.png";

const Step3 = ({ data, section }) => {
  const [all, setAll] = useState([]);

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.top = "100px";
      window.document.getElementById("launcher-frame").style.right = "5px";
    }

    window.scrollTo(0, window.document.body.scrollHeight);

    setAll(data.content);
  }, []);

  return (
    <>
      <div className={classes.StepContainer}>
        <span className={classes.title}>{section.title} </span>
        <div className={classes.ContentWrapper}>
          <Carousel>
            {all.map((item) => (
              <div className={classes.cardContainer}>
                <div
                  className={classes.cardHeader}
                  style={{
                    backgroundColor:
                      item.do === true
                        ? "rgb(25, 171, 14)"
                        : item.do === "info"
                        ? "#73818F"
                        : "red",
                  }}
                >
                  <span>
                    {item.do === true
                      ? "Do"
                      : item.do === "info"
                      ? "Do's & Don'ts"
                      : "Don't"}
                  </span>
                </div>
                <div className={classes.imageWrapper}>
                  <img
                    style={
                      item.do === true
                        ? {
                            width: "50px",
                            height: "50px",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }
                        : item.do === "info"
                        ? {
                            width: "74px",
                            height: "86px",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }
                        : {
                            width: "50px",
                            height: "50px",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }
                    }
                    src={
                      item.do === true
                        ? DoIcon
                        : item.do === "info"
                        ? SwipeIcon
                        : DontIcon
                    }
                  />
                </div>

                <div
                  style={{
                    fontSize: item.id === 0 && "14px",
                    marginTop: item.id === 0 && "-20px",
                  }}
                  dangerouslySetInnerHTML={createMarkup(item.text)}
                  className={classes.cardContent}
                ></div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Step3;
