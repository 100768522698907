import React, { useEffect } from "react";
import Checkbox from "../../components/checkbox";
import classes from "./complete.module.scss";
import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Tick from "./completionTick.svg";
const Complete = (props) => {
  useEffect(() => {
    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData.section < 11) {
      props.setProgress({ section: 11, progress: 0 });
    }
  }, [props.progressData]);
  return (
    <div style={{ width: "90%", margin: "10px auto" }}>
      <span className={classes.Title}>
        Induction <br /> Complete!
      </span>
      <div
        style={{
          marginTop: "4vh",
          marginBottom: "4vh",
        }}
      >
        <div className={classes.CheckboxWrapper}>
          <img src={Tick} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px 30px 0px 30px",
        }}
      >
        <span className={classes.Content}>
          We look forward to seeing you on site and keeping you safe!
        </span>
        <span className={classes.Content}>
          And remember, if you are ever in doubt - ask your Supervisor.
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
