import React, { useState, useEffect } from "react";
import classes from "./selectImage.module.scss";
import ExampleImage from "../../../../../../assets/images/whatwedo.png";
import Checkbox from "../../../../../../components/checkbox";
import { ChoiseContainer } from "./styles";
import WrongIcon from "../assets/wrongIcon.svg";
import CorrectIcon from "../assets/correctIcon.svg";

const SelectImages = ({
  questionData,
  assesmentData,
  setSelectedAssesment,
  setSelectedOption,
  result,
  retry,
  setRetry,
}) => {
  useEffect(() => {
    if (retry) {
      const newOptions = questionData.options.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });

      setOptions(newOptions);
      setRetry(false);
    }
  });

  const handleOptions = questionData.options.map((item) => {
    return {
      ...item,
      checked: false,
    };
  });

  const [options, setOptions] = useState(handleOptions);

  const setCheckBoxHandler = (val) => {
    if (result === null) {
      const newOptions = options.map((item) => {
        if (item.id === val) {
          return {
            ...item,
            checked: !item.checked,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      const newAssesmentData = assesmentData.questions.map((item) => {
        if (item.id === questionData.id) {
          return { ...questionData, options: newOptions };
        } else {
          return item;
        }
      });

      const newData = {
        ...assesmentData,
        questions: newAssesmentData,
      };

      const optionIds = newOptions
        .map((item) => {
          if (item.checked === true) {
            return item.id;
          } else {
            return null;
          }
        })
        .filter((item) => item != null);

      setSelectedOption({ questionId: questionData.id, optionIds: optionIds });
      setOptions(newOptions);
      setSelectedAssesment(newData);
    }
  };

  return (
    <div
      style={{
        paddingBottom: "200px",
        position: "relative",
      }}
    >
      {result != null && result?.is_correct === 1 ? (
        <>
          <div className={classes.successContainer}>
            <span>Correct - great work</span>
          </div>
        </>
      ) : (
        result != null &&
        result?.is_correct === 0 && (
          <div className={classes.errorContainer}>
            <span>Incorrect - please click ’Retry’ to continue. </span>
          </div>
        )
      )}
      <div style={{ width: "90%", margin: "10px auto" }}>
        <span className={classes.QuestionTitle}>{questionData.name}</span>
      </div>
      {options &&
        options.map((item, index) => (
          <ChoiseContainer
            key={index}
            style={{
              backgroundImage: `url(${item.image})`,
            }}
            checked={item.checked}
            className={classes.ChoiseContainer}
            onClick={() => setCheckBoxHandler(item.id)}
          >
            <div className={classes.SelectedCheckboxWrapper}>
              {item.checked && result?.is_correct === 1 ? (
                <img className={classes.statusIcon} src={CorrectIcon} />
              ) : (
                item.checked &&
                result?.is_correct === 0 && (
                  <img className={classes.statusIcon} src={WrongIcon} />
                )
              )}
            </div>
          </ChoiseContainer>
        ))}
    </div>
  );
};

export default SelectImages;
