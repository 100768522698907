import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeSelectUser } from "../../../../store/auth/selector";
import { makeSelectAssesmentResult } from "../../../../store/assesment/selector";

import { createStructuredSelector } from "reselect";

import { withRouter } from "react-router-dom";
import classes from "./completion.module.scss";
import Checkbox from "../../../../components/checkbox";
import Tick from "./tickIcon";
const Complete = ({ user, section, getProgress, result }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProgress();
  }, []);
  return (
    <div
      className={classes.CompletionContainer}
      style={{
        backgroundColor: section.color,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span className={classes.CompletionTitle}>{section.title}</span>
        <Tick color={section.color} />
        <>
          <span className={classes.CompletionText}>
            {result && result?.passed === 1
              ? "Great work " + `${user.first_name}` + "!"
              : result && result?.passed === 0 && "Failed"}
          </span>
          {section.id === 8 ? (
            <span className={classes.CompletionSubText}>
              You have now competed the Life Saving Rules section. Please click
              the button below to continue to the final two steps of this
              induction experience.
            </span>
          ) : (
            <span className={classes.CompletionSubText}>
              This section is now complete!
              <br /> Please click the ‘Continue’ button below to <br />
              continue to the next Life Saving Rule.
            </span>
          )}
        </>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  result: makeSelectAssesmentResult(),
});

export default connect(mapStateToProps)(withRouter(Complete));
