import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./postLogin.module.scss";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { connect } from "react-redux";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReason,
} from "../../store/auth/selector";
import { createStructuredSelector } from "reselect";

const PostLoginFailure = (props) => {
  return (
    <div style={{ background: "#F86C6B" }} className={classes.PopUpContainer}>
      <div className={classes.PopUpTextWrapper}>
        <span className={classes.PopUpTextFailure}>
          <span className={classes.PopUpName}>
            Thanks {props.user && props.user.first_name}
          </span>
          ,
          <br />
          <br />
          {props.reason && props.reason.length === 2 ? (
            <span>
              Looks like you don't have a current Sitesafe Card and you haven't
              read & understood your Company Task Analysis. <br />
              <br /> Please make sure to obtain your card and read & understand
              the Company Task Analysis before starting the induction.
            </span>
          ) : props.reason[0].id === 1 ? (
            <span>
              Looks like you don't have a current Sitesafe Card.
              <br />
              <br />
              Please make sure to obtain your card before starting the
              induction.
            </span>
          ) : (
            <span>
              Looks like you haven't read & understood your Company Task
              Analysis.
              <br />
              <br /> Please make sure to read & understand the Company Task
              Analysis before starting the induction.
            </span>
          )}
        </span>
      </div>
      <Footer>
        <Button
          theme={"gray"}
          textColor={"#1d1d1d"}
          text={"OK! I'll get it sorted!"}
          onClick={() => props.history.push("/login")}
          color={"#F0F3F5"}
        />
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  reason: makeSelectReason(),
});

export default connect(mapStateToProps)(withRouter(PostLoginFailure));
