// @flow
import { all, fork } from "redux-saga/effects";
import loginRequestSaga from "./sagas/loginRequest";
import postLoginRequestSaga from "./sagas/postLoginRequest";
import supervisorLoginRequestSaga from "./sagas/supervisorLoginRequest";

function* authSaga() {
  yield all([
    fork(loginRequestSaga),
    fork(postLoginRequestSaga),
    fork(supervisorLoginRequestSaga),
  ]);
}

export default authSaga;
