import produce from "immer";
import {
  GET_CONTRACTOR_LIST_REQUEST,
  GET_CONTRACTOR_LIST_REQUEST_ERROR,
  GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
  GET_CONTRACTOR_STATS_REQUEST,
  GET_CONTRACTOR_STATS_REQUEST_ERROR,
  GET_CONTRACTOR_STATS_REQUEST_SUCCESS,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_REQUEST_ERROR,
  GET_DASHBOARD_REQUEST_SUCCESS,
  SET,
} from "./constants";

export const initialState = {
  error: null,
  loading: false,
  contractorList: {
    data: undefined,
  },
  dashboard: null,
  contractorStats: null,
  sidebar: "responsive",
};

const progressReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case GET_CONTRACTOR_LIST_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_CONTRACTOR_LIST_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.contractorList = action.data;
        break;
      case GET_CONTRACTOR_LIST_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_CONTRACTOR_STATS_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_CONTRACTOR_STATS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.contractorStats = action.data;
        break;
      case GET_CONTRACTOR_STATS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_DASHBOARD_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_DASHBOARD_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.dashboard = action.data;
        break;
      case GET_DASHBOARD_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET:
        draftState.sidebar = action.data;

        break;
      default:
        break;
    }
  });
};

export default progressReducer;
