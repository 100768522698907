import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import "./assets/scss/style.scss";
//routes
import { routes, authRoutes } from "./routes/allRoutes";

//middleware start
import Authmiddleware from "./middleware/auth";
import NonAuthmiddleware from "./middleware/nonAuth";

//midleware end

import Layout from "./layouts/contractor";
import SupervisorLayout from "./layouts/supervisor";

import Loading from "./components/loading";
import Splash from "./pages/Splash";
import { enableES5 } from "immer";

import { icons } from "./assets/icons";
import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
enableES5();

React.icons = icons;

export const success = (val) => toast.success(`${val}`);
export const error = (val) => toast.error(`${val}`);

const App = () => {
  const isMain = window.location.pathname === "/";

  const [isSplashVisible, setSplashVisible] = useState(isMain ? true : false);

  const trackingId = "G-240XE9FT93";
  ReactGA.initialize(trackingId);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Suspense fallback={Loading}>
      {isSplashVisible ? (
        <Splash />
      ) : (
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              type={route.type}
              path={route.path}
              layout={route.type ? SupervisorLayout : Layout}
              component={route.component}
              key={idx}
            />
          ))}

          {routes.map((route, idx) => (
            <Authmiddleware
              type={route.type}
              path={route.path}
              layout={route.type ? SupervisorLayout : Layout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      )}
      <ToastContainer />
    </React.Suspense>
  );
};
export default App;
