/*  SET SELECTED_STEP RESULT */
export const GET_CONTRACTOR_LIST_REQUEST =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST";
export const GET_CONTRACTOR_LIST_REQUEST_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST_SUCCESS";
export const GET_CONTRACTOR_LIST_REQUEST_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST_ERROR";

export const GET_CONTRACTOR_STATS_REQUEST =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST";
export const GET_CONTRACTOR_STATS_REQUEST_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST_SUCCESS";
export const GET_CONTRACTOR_STATS_REQUEST_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST_ERROR";

export const GET_DASHBOARD_REQUEST = "SUPERVISOR/GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_REQUEST_SUCCESS =
  "SUPERVISOR/GET_DASHBOARD_REQUEST_SUCCESS";
export const GET_DASHBOARD_REQUEST_ERROR =
  "SUPERVISOR/GET_DASHBOARD_REQUEST_ERROR";

export const SET = "SUPERVISOR/GET_DASHBOARD_REQUEST_ERROR";
