// @flow
import { all, fork } from "redux-saga/effects";
import getContractorListRequestSaga from "./sagas/getContractorList";
import getContactorStatsRequestSaga from "./sagas/getContractorStats";
import getDashboardRequestSaga from "./sagas/getDashboard";

function* contractorListRequest() {
  yield all([
    fork(getContractorListRequestSaga),
    fork(getContactorStatsRequestSaga),
    fork(getDashboardRequestSaga),
  ]);
}

export default contractorListRequest;
