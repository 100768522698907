import React, { useEffect, useState } from "react";

import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
} from "@coreui/react";

import { connect } from "react-redux";
//action start
import { getContractorStats } from "../../../store/supervisor/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectContractorList,
  makeSelectError,
  makeSelectLoading,
  makeSelectContractorStats,
} from "../../../store/supervisor/selector";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";
import User from "./user.svg";
import Flag from "./flag.svg";
import Attention from "./attention.svg";
import CorrectIcon from "./correctIcon.svg";
import classes from "./detail.module.scss";
import Email from "./email.svg";
import Blank from "./blank.svg";

import mockData from "../../Induction/mockData";
import { sections } from "../../../utils/enums";

const Modal = ({ modal, setModal, statistics, loading }) => {
  const [dataSet, setDataSet] = useState([]);
  const [assesmentTries, setTries] = useState([]);
  useEffect(() => {
    if (statistics != null) {
      const data = statistics?.assesmentTries.map((item) => {
        return item.assesment;
      });
      setDataSet(data);
      setTries(statistics?.assesmentTries);
      const failed = statistics.assesmentTries.filter(
        (item) => item.assesment === 3 && item.passed === 0
      );
    }
  }, [statistics]);

  return (
    <>
      <CModal show={modal} onClose={() => setModal(!modal)}>
        {!loading ? (
          <>
            {statistics && assesmentTries && (
              <>
                <CModalHeader closeButton>
                  <CModalTitle>
                    <h3 style={{ fontWeight: "600" }}>Contractor Profile</h3>
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <div className={classes.detailContainer}>
                    <div className={classes.detailSection2}>
                      <div className={classes.detailContent}>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            marginTop: "20px",
                          }}
                        >
                          <div>
                            <img
                              style={{ width: "135px", margin: "15px" }}
                              src={User}
                            />
                          </div>
                          <div className={classes.detailInfo}>
                            <div className={classes.detailInfoContainer}>
                              <h3 className={classes.detailName}>
                                {modal && modal.data.first_name}{" "}
                                {modal && modal.data.last_name}
                              </h3>
                              <img
                                style={{
                                  width: "30px",
                                  justifySelf: "center",
                                  alignItems: "center",
                                }}
                                src={Flag}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "15px",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                style={{ width: "20px", marginRight: "5px" }}
                                src={Email}
                              />
                              <span>{statistics?.email}</span>
                            </div>
                            <span className={classes.detailSubTitle}>
                              <b>Joined: </b>{" "}
                              {modal && modal.data.date_joined.slice(0, 10)}
                            </span>
                            <span className={classes.detailText}>
                              <b>Site Safe Card: </b> Current
                            </span>
                            <span className={classes.detailText}>
                              <b> Company: </b> {statistics?.company}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={classes.detailContent}>
                        <h3 className={classes.detailTitle}>Sites Inducted</h3>
                      </div>
                      <div className={classes.detailContent}>
                        <h3 className={classes.detailTitle}>
                          Health and Safety Progress
                        </h3>
                      </div>
                    </div>
                    <div className={classes.detailContent}>
                      <h3 className={classes.detailTitle}>Progress</h3>
                      <div className={classes.progressBarContainer}>
                        {mockData.map((a) => (
                          <>
                            <div
                              style={{
                                backgroundColor:
                                  statistics?.assesmentTries?.filter(
                                    (item) =>
                                      item.assesment === a.id &&
                                      item.passed === 0
                                  )?.length > 0
                                    ? a.color
                                    : statistics?.assesmentTries?.filter(
                                        (item) =>
                                          item.assesment === a.id &&
                                          item.passed === 1
                                      )?.length > 0
                                    ? a.color
                                    : "",
                              }}
                              className={
                                statistics?.assesmentTries?.filter(
                                  (item) =>
                                    item.assesment === a.id && item.passed === 0
                                )?.length > 0
                                  ? classes.progressFail
                                  : statistics?.assesmentTries?.filter(
                                      (item) =>
                                        item.assesment === a.id &&
                                        item.passed === 1
                                    )?.length > 0
                                  ? classes.progressSuccess
                                  : classes.progressBlank
                              }
                            >
                              <span className={classes.slug}>{a.slug}</span>
                            </div>
                            <div className={classes.tooltip}>
                              <span className={classes.tooltiptext}>
                                {`${
                                  statistics?.assesmentTries?.filter(
                                    (item) =>
                                      item.assesment === a.id &&
                                      item.passed === 0
                                  ).length
                                } failed attempts.`}
                              </span>
                              <img
                                className={
                                  statistics?.assesmentTries?.filter(
                                    (item) =>
                                      item.assesment === a.id &&
                                      item.passed === 0
                                  )?.length > 0
                                    ? classes.progressFailImage
                                    : statistics?.assesmentTries?.filter(
                                        (item) =>
                                          item.assesment === a.id &&
                                          item.passed === 1
                                      )?.length > 0
                                    ? classes.progressSuccessImage
                                    : classes.progressSuccessImage
                                }
                                src={
                                  statistics?.assesmentTries?.filter(
                                    (item) =>
                                      item.assesment === a.id &&
                                      item.passed === 0
                                  )?.length > 0
                                    ? Attention
                                    : statistics?.assesmentTries?.filter(
                                        (item) =>
                                          item.assesment === a.id &&
                                          item.passed === 1
                                      )?.length > 0
                                    ? CorrectIcon
                                    : Blank
                                }
                              ></img>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </CModalBody>
                <CModalFooter>
                  <CButton color="secondary" onClick={() => setModal(!modal)}>
                    Close
                  </CButton>
                </CModalFooter>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        )}
      </CModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  contractors: makeSelectContractorList(),
  statistics: makeSelectContractorStats(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getContractorStatistics: (val) => dispatch(getContractorStats(val)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));
