import classes from "./section.module.scss";
import React, { useEffect, useState } from "react";
import { usePrevious } from "../../../utils/hooks/";

//components start
import Footer from "../../../components/footer";
import Icon from "../../../components/icon";
import Button from "../../../components/button";
import FooterProgress from "./FooterProgress";
//components end

//steps start
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Assesment from "./Assesment";
//steps end
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  makeSelectAssesmentResult,
  makeSelectLoading,
} from "../../../store/assesment/selector";
import { createStructuredSelector } from "reselect";

const Section = ({
  section,
  setSection,
  step,
  setStep,
  progress,
  setProgress,
  assesmentData,
  createNewAssesment,
  getAssesment,
  setAssesmentQuestion,
  assesmentTryId,
  rules,
  setProgressReq,
  getProgress,
  progressData,
  history,
  result,
  resetCurrentResult,
  loading,
}) => {
  useEffect(() => {
    if (section) {
      getAssesment({ id: section?.aid });
      if (progressData.section < section.id) {
        setProgressReq({ section: section.id, progress: "0" });
      }
    }
    resetCurrentResult();
  }, []);

  useEffect(() => {
    if (assesmentData) {
      setSelectedAssesment(assesmentData?.data);
    }
  }, [assesmentData]);

  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [assesmentStep, setAssesmentStep] = useState();
  const [selectedAssesment, setSelectedAssesment] = useState();

  const [assesment, setAssesment] = useState();
  const [completion, setCompletion] = useState(false);
  const [selectedOptions, setSelectedOption] = useState({});
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/").length === 3) {
      setCompletion(false);
      setStep(1);
      setSelectedOption({});
      // setSelectedAssesment(null);
      setAssesment(false);
      setAssesmentStep(0);
      setProgress(16.666);
      resetCurrentResult();
      history.push("/induction");
    }
  });

  const setNext = () => {
    const nextItem = rules.find((item) => {
      return item.id === section.id + 1;
    });
    setSection(nextItem);
    setCompletion(false);
    setStep(1);
    setSelectedOption({});
    // setSelectedAssesment(null);
    setAssesment(false);
    setAssesmentStep(0);
    setProgress(16.666);
  };

  const setSelectedProgress = () => {
    resetCurrentResult();
    if (section?.id <= progressData?.section) {
      setNext();
    } else {
      setProgressReq({
        section: section?.id,
        setNext,
      });
    }
  };

  const nextSection = () => {
    if (section?.id === 8) {
      history.push("/card-system");
      setSelectedProgress();
    } else {
      const nextItem = rules.find((item) => {
        return item.id === section.id + 1;
      });
      setProgressReq({ section: section.id, progress: 100 });
      setProgressReq({ section: section.id + 1, progress: 0 });
      getAssesment({
        id: nextItem.aid,
        setSelectedProgress,
      });
    }
  };

  return (
    <React.Fragment>
      {assesmentData && assesment ? (
        <div className={classes.InductionContainer}>
          {!completion && (
            <Icon
              onClick={() => {
                setAssesment(false);
                setProgress(50);
                setStep(3);
                setCompletion(false);
                setAssesmentStep(0);
                resetCurrentResult();
              }}
              style={{ position: "absolute", left: "20px" }}
              shadow={true}
              color={"#20A8D8"}
              width={"46px"}
              height={"46px"}
              type={"BackArrow"}
            />
          )}
          <span className={classes.Heading}>
            {completion ? "Life Saving Rules" : "Knowledge Check"}
          </span>
          <Assesment
            setRetry={setRetry}
            retry={retry}
            setSelectedAnswer={setSelectedAnswer}
            setAssesmentStep={setAssesmentStep}
            assesmentStep={assesmentStep}
            setAssesment={setAssesment}
            setStep={setStep}
            setProgress={setProgress}
            section={section}
            completion={completion}
            setCompletion={setCompletion}
            assesmentData={selectedAssesment}
            setSelectedAssesment={setSelectedAssesment}
            createNewAssesment={createNewAssesment}
            assesmentTryId={assesmentTryId}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOption}
            getProgress={getProgress}
            result={result}
            resetCurrentResult={resetCurrentResult}
          />
        </div>
      ) : (
        <div className={classes.InductionContainer}>
          {step === 1 && (
            <Icon
              onClick={() => {
                setSection();
                getProgress();
                resetCurrentResult();
              }}
              style={{ position: "absolute", left: "20px" }}
              shadow={true}
              color={"#20A8D8"}
              width={"46px"}
              height={"46px"}
              type={"BackArrow"}
            />
          )}
          <span className={classes.Heading}>Life Saving Rules</span>
          <div
            className={classes.SectionContainer}
            style={{
              margin:
                step === 3 && section?.questions[2].type === "drag-drop" && "0",
              background: section?.color,
            }}
          >
            <div
              className={classes.SectionWrapper}
              style={{ width: step === 3 && "99%" }}
            >
              {step === 1 && (
                <span className={classes.SectionTitle}>{section?.title}</span>
              )}
              {step === 1 && <Step1 data={section?.questions[0]} />}
              {step === 2 && <Step2 data={section?.questions[1]} />}
              {step === 3 && (
                <Step3 section={section} data={section?.questions[2]} />
              )}
            </div>
          </div>
        </div>
      )}
      <Footer>
        <>
          {completion ? (
            <div className={classes.FooterCompletion}>
              {result && result?.passed === 0 ? (
                <div className={classes.FooterButtonWrapper}>
                  <Button
                    text={"Retake"}
                    onClick={() => {
                      setSelectedOption({});
                      setSelectedAssesment(assesmentData.data);
                      setAssesmentStep(1);
                      setCompletion(false);
                      setProgress(75);
                      createNewAssesment({ id: section.aid });
                      resetCurrentResult();
                    }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <div className={classes.FooterButtonWrapper}>
                    <Button text={"Continue"} onClick={nextSection} />
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : (
            <FooterProgress
              retry={retry}
              setRetry={setRetry}
              result={result}
              resetCurrentResult={resetCurrentResult}
              setProgressReq={setProgressReq}
              selectedAssesment={selectedAssesment}
              setSection={setSection}
              assesmentTryId={assesmentTryId}
              selectedAnswer={selectedAnswer}
              step={step}
              progress={progress}
              setStep={setStep}
              setCompletion={setCompletion}
              setProgress={setProgress}
              setAssesment={setAssesment}
              assesment={assesment}
              section={section}
              getAssesment={getAssesment}
              assesmentData={assesmentData}
              setAssesmentQuestion={setAssesmentQuestion}
              assesmentStep={assesmentStep}
              setAssesmentStep={setAssesmentStep}
              selectedOptions={selectedOptions}
              progressData={progressData}
              loading={loading}
              createNewAssesment={createNewAssesment}
              setSelectedOption={setSelectedOption}
              setSelectedAssesment={setSelectedAssesment}
            />
          )}
        </>
      </Footer>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  result: makeSelectAssesmentResult(),
  loading: makeSelectLoading(),
});

export default connect(mapStateToProps)(withRouter(Section));
