import React from "react";
import ReactPlayer from "react-player/vimeo";

const VideoPlayer = (props) => {
  return (
    <ReactPlayer
      style={{ marginTop: "20px" }}
      height={"282px"}
      width={"100%"}
      url="https://vimeo.com/483475050/a1edf94934"
      controls={true}
      playsinline={true}
      autopause={"true"}
    />
  );
};

export default VideoPlayer;
