import React from "react";

const Divider = () => {
  return (
    <div
      style={{
        marginTop: "15px",
        width: "1px",
        height: "80%",
        borderRadius: "2px",
        backgroundColor: "#c8ced3",
        marginRight: "15px",
      }}
    ></div>
  );
};

export default Divider;
