import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./postLogin.module.scss";
import Button from "../../components/button";
import Footer from "../../components/footer";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "../../store/auth/selector";
import { createStructuredSelector } from "reselect";

const PostLoginSuccess = (props) => {
  return (
    <div className={classes.PopUpContainer}>
      <div className={classes.PopUpTextWrapper}>
        <span className={classes.PopUpText}>
          Thanks,&nbsp;
          <span className={classes.PopUpName}>
            {props.user && props.user.first_name}
          </span>
          !
          <br />
          <br />
          You’re ready to learn about our <br />6 Life Saving Rules.
        </span>
      </div>
      <Footer>
        <Button
          theme={"gray"}
          textColor={"#1d1d1d"}
          text={"OK! great"}
          onClick={() => props.history.push("/welcome")}
          color={"#efefef"}
        />
      </Footer>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

export default connect(mapStateToProps)(withRouter(PostLoginSuccess));
