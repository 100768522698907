import React from "react";
import classes from "./icon.module.scss";
import Next from "../../assets/icons/next.svg";
import Back from "../../assets/icons/back.svg";
import BackArrow from "../../assets/icons/arrow.svg";

const Icon = (props) => {
  return (
    <div
      style={{
        boxShadow:
          props.shadow &&
          "0px 2px 4px rgba(27, 28, 88, 0.2), 0px 20px 40px rgba(62, 65, 247, 0.13)",
        backgroundColor: props.color,
        width: props.width,
        height: props.height,
        ...props.style,
      }}
      className={classes.Icon}
      onClick={props.onClick}
    >
      <img
        alt={`${props.type}`}
        className={classes.Image}
        src={
          props.type === "Next"
            ? Next
            : props.type === "Back"
            ? Back
            : BackArrow
        }
      />
    </div>
  );
};

export default Icon;
