import React from "react";
import ThumbnailIcon from "../../assets/icons/thumbnail.svg";
import Checkbox from "../checkbox";
import {
  CardContainer,
  TitleWrapper,
  Title,
  MiddleArea,
  ImageBox,
  Thumbnail,
  ProgressBar,
  Progress,
  CorrectBox,
} from "./styles";

const Card = ({ onClick, color, image, completed, progress, title, icon }) => {
  return (
    <CardContainer onClick={onClick} color={color} image={image}>
      <TitleWrapper>
        <ImageBox>
          <Thumbnail src={icon}></Thumbnail>
        </ImageBox>
        <Title>{title}</Title>
      </TitleWrapper>
      <ProgressBar>
        <Progress progress={progress} />
      </ProgressBar>
      <CorrectBox>{completed && <Checkbox checked={true} />}</CorrectBox>
    </CardContainer>
  );
};

export default Card;
