export default [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/supervisor-dashboard",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Contractors",
    to: "/supervisor-contractors",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Company",
    to: "/supervisor-company",
    icon: "cil-bar-chart",
  },
];
