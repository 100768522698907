import React from "react";
import classes from "./button.module.scss";
import ReactLoading from "react-loading";

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      id={props.id}
      type={props.type}
      className={
        props.theme === "gray"
          ? classes.GrayButton
          : props.theme === "light"
          ? classes.LightButton
          : classes.Button
      }
    >
      {props.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={props.textColor ? props.textColor : "white"}
            height={30}
            width={30}
          />
        </div>
      ) : (
        <span
          style={{ color: props.textColor ? props.textColor : "" }}
          className={classes.Text}
        >
          {props.text}
        </span>
      )}
    </button>
  );
};

export default Button;
