import React, { useEffect } from "react";
import classes from "./cardsystem.module.scss";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";

import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const CardSystemImage = process.env.PUBLIC_URL + "/cardsystem.jpg";

const CardSystem = (props) => {
  useEffect(() => {
    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData.section < 9) {
      props.setProgress({ section: 9, progress: 0 });
    }
  }, [props.progressData]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "45px",
        position: "relative",
      }}
    >
      <span className={classes.Title}>Our Card System</span>
      <span className={classes.Content}>
        Our 6 life Saving Rules are very important. Breaking these rules can
        result in death.
      </span>
      <span className={classes.Content}>
        When anyone breaks these rules intentionally, we take action. This is
        part of how we keep our people safe.
      </span>
      <img className={classes.Image} src={CardSystemImage} />
      <Footer>
        <Link className={classes.Link} to="/site-rules">
          <Button id="apply-button" text="Next" type="submit" />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSystem);
