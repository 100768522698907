import {
  LOGIN_REQUEST,
  SUPERVISOR_LOGIN_REQUEST,
  POST_LOGIN_REQUEST,
  SUPERVISOR_LOGOUT,
  RESET_ERROR,
  REMOVE_TOKEN,
} from "./constants";

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    ...payload,
  };
};
export const supervisorLoginRequest = (payload) => {
  return {
    type: SUPERVISOR_LOGIN_REQUEST,
    ...payload,
  };
};
export const supervisorLogout = (payload) => {
  return {
    type: SUPERVISOR_LOGOUT,
    ...payload,
  };
};

export const postLoginRequest = (payload) => {
  return {
    type: POST_LOGIN_REQUEST,
    payload,
  };
};
export const removeToken = (payload) => {
  return {
    type: REMOVE_TOKEN,
    payload,
  };
};

export const resetError = (payload) => {
  return {
    type: RESET_ERROR,
    payload,
  };
};
