// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_ASSESMENT_REQUEST,
  SET_ASSESMENT_REQUEST_SUCCESS,
  SET_ASSESMENT_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* setAssesmentRequest(data) {
  const params = new URLSearchParams();
  params.append("assesmentTryId", data.assesmentTryId);
  params.append("questionId", data.questionId);

  if (data.optionId === undefined) {
    params.append("optionIds", data.optionIds);
  } else {
    params.append("optionId", data.optionId);
  }
  try {
    const response = yield call(axios.put, "/assesment-try/", params);
    yield put({
      type: SET_ASSESMENT_REQUEST_SUCCESS,
      data: response.data,
    });
    data && data.setComp();
  } catch (error) {
    yield put({
      type: SET_ASSESMENT_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setAssesmentRequestSaga() {
  yield takeLatest(SET_ASSESMENT_REQUEST, setAssesmentRequest);
}
