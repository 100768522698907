import { all, call } from "redux-saga/effects";
import authSaga from "./auth/saga";
import layoutSaga from "./layouts/saga";
import assesmentSaga from "./assesment/saga";
import progressSaga from "./progress/saga";
import stepsSaga from "./steps/saga";
import supervisorSaga from "./supervisor/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    assesmentSaga(),
    progressSaga(),
    stepsSaga(),
    supervisorSaga(),
  ]);
}
