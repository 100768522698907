import { baseUrl } from "./config";
import { push } from "connected-react-router";
import axios from "axios";
import { createBrowserHistory } from "history";

const instance = axios.create({
  baseURL: baseUrl,
});

const token = localStorage.getItem("AUTH_TOKEN");
if (token) {
  instance.defaults.headers.common["Content-Type"] =
    "Origin,Accept, X-ACCESS_TOKEN, X-Token, HTTP_X_TOKEN, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers";
  instance.defaults.headers.common["X-Token"] = `${token}`;
}
instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.status === 403 || response.status === 401) {
      const history = createBrowserHistory();
      history.push("/logout");
      window.location.reload();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
