import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAuth = (state) => state.auth || initialState;

const makeSelectError = () =>
  createSelector(selectAuth, (state) => state.error);

const makeSelectUser = () => createSelector(selectAuth, (state) => state.user);

const makeSelectSupervisor = () =>
  createSelector(selectAuth, (state) => state.supervisor);

const makeSelectReason = () =>
  createSelector(selectAuth, (state) => state.reason);

const makeSelectLoading = () =>
  createSelector(selectAuth, (state) => state.loading);

export {
  selectAuth,
  makeSelectError,
  makeSelectUser,
  makeSelectLoading,
  makeSelectReason,
  makeSelectSupervisor,
};
