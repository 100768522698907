import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";

//action start
import {
  supervisorLoginRequest,
  resetError,
} from "../../../store/auth/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "../../../store/auth/selector";
//selectors end
import ReactLoading from "react-loading";

const Login = ({ onSubmitForm, error, resetErrorReq, loading }) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    resetErrorReq();
  }, []);

  const initialValues = {
    name: name,
    password: password,
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      onSubmitForm({ values });
                    }}
                  >
                    {({ handleSubmit }) => {
                      return (
                        <CForm onSubmit={handleSubmit}>
                          <h1>Login</h1>
                          <p className="text-muted">Sign In to your account</p>
                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-user" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              type="text"
                              placeholder="Username"
                              autoComplete="username"
                            />
                          </CInputGroup>
                          <CInputGroup className="mb-0">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-lock-locked" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              type="password"
                              placeholder="Password"
                              autoComplete="current-password"
                            />
                          </CInputGroup>
                          <div
                            style={{
                              height: "40px",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {error && (
                              <span style={{ marginLeft: "5px", color: "red" }}>
                                {error}
                              </span>
                            )}
                          </div>
                          <CRow>
                            <CCol xs="6">
                              <CButton
                                type={"submit"}
                                color="primary"
                                className="px-4"
                              >
                                {loading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flex: "1",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#fff"}
                                      height={25}
                                      width={25}
                                    />
                                  </div>
                                ) : (
                                  "Login"
                                )}
                              </CButton>
                            </CCol>
                            <CCol xs="6" className="text-right">
                              <CButton
                                disabled={true}
                                color="link"
                                className="px-0"
                              >
                                Forgot password?
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      );
                    }}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(supervisorLoginRequest(val)),
    resetErrorReq: (val) => dispatch(resetError(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
