import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Auth from "../pages/Auth";
import PostLogin from "../pages/PostLogin";
import PostLoginSuccess from "../pages/PostLogin/success";
import PostLoginFailure from "../pages/PostLogin/failure";

//Dummy Logout
import Logout from "../pages/DummyLogout";

// Pages
import Welcome from "../pages/Welcome";
import LookAround from "../pages/LookAround";
import Induction from "../pages/Induction";
import CardSystem from "../pages/CardSystem";
import SiteRules from "../pages/SiteRules";
import Complete from "../pages/Complete";

//Supervisor Related Page
import SupervisorAuth from "../pages/Supervisor/Auth";
import ContractorList from "../pages/Supervisor/ContractorList";
import ContractorDetail from "../pages/Supervisor/ContractorStats";
import SupervisorDashboard from "../pages/Supervisor/Dashboard";
import SupervisorCompany from "../pages/Supervisor/Company";

const supervisorAuthRoutes = [];
const supervisorRoutes = [];

const routes = [
  {
    path: "/supervisor-dashboard",
    component: SupervisorDashboard,
    type: "supervisor",
  },
  {
    path: "/supervisor-contractors",
    component: ContractorList,
    type: "supervisor",
  },
  {
    path: "/supervisor-company",
    component: SupervisorCompany,
    type: "supervisor",
  },
  {
    path: "/supervisor-contractor-detail",
    component: ContractorDetail,
    type: "supervisor",
  },
  { path: "/welcome", component: Welcome },
  { path: "/take-a-look", component: LookAround },
  { path: "/induction", component: Induction },
  { path: "/card-system", component: CardSystem },
  { path: "/site-rules", component: SiteRules },
  { path: "/complete", component: Complete },
  { path: "/logout", component: Logout },

  // this route should be at the end of all other routes
  { path: "/", component: () => <Redirect to="/welcome" /> },
];

const authRoutes = [
  { path: "/supervisor-login", component: SupervisorAuth, type: "supervisor" },
  { path: "/login", component: Auth },
  { path: "/post-login/success", component: PostLoginSuccess },
  { path: "/post-login/failure", component: PostLoginFailure },
  { path: "/post-login", component: PostLogin },
];

export { routes, authRoutes, supervisorAuthRoutes, supervisorRoutes };
