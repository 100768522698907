import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
// sidebar nav config
import navigation from "./_nav";
import classes from "./nav.module.scss";

//const Logo = process.env.PUBLIC_URL + "/logo.png";
const Logo = "https://immersivefrl.s3-ap-southeast-2.amazonaws.com/fl_logo_small.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow?.sidebarShow);

  return (
    <CSidebar
      style={{ backgroundColor: "#2f353a" }}
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img style={{ width: "90%" }} src={Logo} />
      </CSidebarBrand>
      <CSidebarNav>
        <div className={classes.sidebar}>
          <CCreateElement
            items={navigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </div>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
