import classes from "./lookAround.module.scss";
import React, { useEffect } from "react";
import Button from "../../components/button";

import {
  getProgressRequest,
  setProgressRequest,
} from "../../store/progress/actions";
import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const VRScene1Image = process.env.PUBLIC_URL + "/vr1-updated.png";
const VRScene2Image = process.env.PUBLIC_URL + "/vr2-updated.png";

const LookAround = ({ history, progressData, getProgress, setProgress }) => {
  useEffect(() => {
    getProgress();
  }, []);

  useEffect(() => {
    if (progressData != null || progressData != undefined) {
      if (progressData?.section < 2) {
        setProgress({ section: 2, progress: 0 });
      }
    }
  }, [progressData]);

  return (
    <div className={classes.Container}>
      <div className={classes.TextContainer}>
        <span className={classes.Heading}>Take a look around</span>
        <span className={classes.Description}>
          Use the buttons below to travel to one of our sites. You can look
          around either by moving your phone, or by dragging with your finger or
          mouse.
        </span>
      </div>
      <div className={classes.VRContainer}>
        <img
          alt="VR Experience 1"
          className={classes.Image}
          src={VRScene1Image}
        />
        <div className={classes.ButtonContainer}>
          <Button
            onClick={() => {
              window.location =
                "/frl_vr/vr_experience_1.html";
            }}
            text={"VR Scene 1"}
          />
        </div>
      </div>
      <div className={classes.VRContainer}>
        <img
          alt="VR Experience 2"
          className={classes.Image}
          src={VRScene2Image}
        />
        <div className={classes.ButtonContainer}>
          <Button
            onClick={() => {
              window.location =
                "/frl_vr/vr_experience_2.html";
            }}
            text={"VR Scene 2"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LookAround);
