import React from "react";
import classes from "./footerProgress.module.scss";
import Icon from "../../../../components/icon";
import Button from "../../../../components/button";
import { sections } from "../../../../utils/enums";

const FooterCompletion = ({
  step,
  progress,
  setStep,
  setCompletion,
  setProgress,
  setAssesment,
  assesment,
  section,
  assesmentData,
  setAssesmentQuestion,
  setAssesmentStep,
  assesmentStep,
  assesmentTryId,
  selectedOptions,
  selectedAssesment,
  setProgressReq,
  progressData,
  resetCurrentResult,
  result,
  loading,
  createNewAssesment,
  setSelectedOption,
  getAssesment,
  setSelectedAssesment,
  setRetry,
}) => {
  const setNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (assesment) {
      if (result != null) {
        if (result.passed === 1) {
          if (assesmentStep === assesmentData.data.total_questions) {
            if (selectedOptions.optionId || selectedOptions.optionIds) {
              setCompletion(true);
              setProgress(100);
            }
          } else {
            if (selectedOptions.optionId || selectedOptions.optionIds) {
              setProgress(
                progress + 50 / (Number(assesmentData.data.total_questions) + 1)
              );
              setAssesmentStep(assesmentStep + 1);
              resetCurrentResult();
            }
          }
        } else if (result.passed === 0 || result.passed === undefined) {
          setSelectedOption({});
          setAssesmentStep(1);
          setCompletion(false);
          setProgress(75);
          createNewAssesment({ id: section.aid });
          setSelectedAssesment(assesmentData.data);
          resetCurrentResult();
          getAssesment({ id: section.aid });
          setRetry(true);
        }
      } else {
        if (assesmentStep === assesmentData.data.total_questions) {
          if (selectedOptions.optionId || selectedOptions.optionIds) {
            setAssesmentQuestion({
              assesmentTryId: assesmentTryId,
              questionId: selectedOptions.questionId,
              optionId: selectedOptions.optionId,
              optionIds: selectedOptions.optionIds,
            });
          }
        } else {
          if (selectedOptions.optionId || selectedOptions.optionIds) {
            setAssesmentQuestion({
              assesmentTryId: assesmentTryId,
              questionId: selectedOptions.questionId,
              optionId: selectedOptions.optionId,
              optionIds: selectedOptions.optionIds,
            });
          }
        }
      }
    } else if (step === 3) {
      if (selectedAssesment != null && progressData) {
        setProgress(
          progress + 50 / (Number(assesmentData.data.total_questions) + 1)
        );
        setAssesment(true);
        setAssesmentStep(1);
      } else {
        setAssesment(true);
        setCompletion(true);
        setProgress(100);
      }
    } else {
      if (progressData?.section > section.id) {
        setStep(step + 1);
        setProgress(progress + 16.666);
      } else {
        if (
          progressData.section === section.id &&
          progressData.progress < progress
        ) {
          setStep(step + 1);
          setProgress(progress + 16.666);
        }
        setProgressReq({
          section: section.id,
          progress: Number(progress + 16.666).toFixed(0),
        });
        setStep(step + 1);
        setProgress(progress + 16.666);
      }
    }
  };

  const prevAction = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (assesment) {
      if (assesmentStep === 1) {
      } else {
        setAssesmentStep(assesmentStep - 1);
        setProgress(
          progress - 50 / (Number(assesmentData.data.total_questions) + 1)
        );
      }
    } else if (step === 1) {
      setStep(1);
      setProgress(16.666);
    } else {
      setStep(step - 1);
      setProgress(progress - 16.666);
      setAssesment(false);
    }
  };

  const nextAction = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (assesment) {
      if (assesmentStep === assesmentData.data.total_questions) {
        if (selectedOptions.optionId || selectedOptions.optionIds) {
          if (section.id <= progressData.section) {
            setNext();
          } else {
            setProgressReq({
              section: section.id,
              progress:
                progressData && section.id <= progressData.section ? 50 : 70,
              setNext,
            });
          }
        }
      } else {
        if (selectedOptions.optionId || selectedOptions.optionIds) {
          if (section.id <= progressData.section) {
            setNext();
          } else {
            setProgressReq({
              section: section.id,
              progress:
                progressData && section.id < progressData.section
                  ? progressData && progressData.progress
                  : Math.round(
                      progress +
                        50 / (Number(assesmentData.data.total_questions) + 1)
                    ),
              setNext,
            });
          }
        }
      }
    } else if (step === 3) {
      if (selectedAssesment != null && progressData) {
        if (section.id <= progressData.section) {
          setNext();
        } else {
          setProgressReq({
            section: section.id,
            progress:
              progressData && section.id > progressData.section
                ? progressData && progressData.progress
                : Math.round(
                    progress +
                      50 / (Number(assesmentData.data.total_questions) + 1)
                  ),
            setNext,
          });
        }
      } else {
        if (section.id <= progressData.section) {
          setNext();
        } else {
          setProgressReq({
            section: section.id,
            progress: 0,
            setNext,
          });
        }
      }
    } else {
      if (section.id <= progressData.section) {
        setNext();
      } else {
        setProgressReq({
          section: section.id,
          progress:
            progressData && section.id <= progressData.section
              ? progressData && progressData.progress
              : progressData && Math.round(progress + 16.666),
          setNext,
        });
      }
    }
  };
  return (
    <div className={classes.FooterProgressContainer}>
      {assesment ? (
        <>
          <Button
            loading={loading}
            text={
              result?.passed && result?.passed === 1
                ? "Next"
                : result?.passed === 0
                ? "Retry"
                : "Submit"
            }
            onClick={nextAction}
          />
        </>
      ) : (
        <>
          <div
            className={classes.BackIconWrapper}
            style={{
              opacity:
                (section.id === 1 && step === 1) || assesmentStep === 1
                  ? 0.3
                  : 1,
            }}
            onClick={() => {
              prevAction();
            }}
          >
            <Icon
              shadow={true}
              color={"#20A8D8"}
              width={"46px"}
              height={"46px"}
              type={"Back"}
            />
          </div>
          <div className={classes.ProgressWrapper}>
            <div
              style={{
                backgroundColor: section.color,
              }}
              className={classes.ProgressBar}
            />
            <div
              className={classes.Progress}
              style={{
                backgroundColor: section.color,
                width: `${progress}%`,
              }}
            />
          </div>
          <div className={classes.NextIconWrapper}>
            <Icon
              onClick={() => {
                nextAction();
              }}
              shadow={true}
              color={"#20A8D8"}
              width={"46px"}
              height={"46px"}
              type={"Next"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FooterCompletion;
