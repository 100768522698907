import React from "react";
import classes from "./drawer.module.scss";
import CloseIcon from "../../../assets/icons/closeIcon.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeSelectProgress } from "../../../store/progress/selector";
import { setSelectedStep } from "../../../store/steps/actions";
import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  resetAssesmentResult,
} from "../../../store/assesment/actions";
import {
  setProgressRequest,
  getProgressRequest,
} from "../../../store/progress/actions";
import { resetSelectedStep } from "../../../store/steps/actions";
import { withRouter } from "react-router-dom";

import { createStructuredSelector } from "reselect";
const DrawerMenu = ({
  progressData,
  show,
  action,
  setStep,
  history,
  getAssesment,
  setAssesmentQuestion,
  createNewAssesment,
  getProgress,
  setProgressReq,
  resetCurrentResult,
}) => {
  const LifeSavingRules = [
    { id: 3, text: "Drugs & Alcohol" },
    { id: 4, text: "Exclusion Zones" },
    { id: 5, text: "Speed Limits" },
    { id: 6, text: "Working with Scaffold" },
    { id: 7, text: "Fall Protection" },
    { id: 8, text: "Dust and Harmful Substances" },
  ];

  return (
    <React.Fragment>
      <div style={{ height: "100vh" }}>
        {show && (
          <img
            alt={"Close Icon"}
            src={CloseIcon}
            className={classes.CloseIcon}
            onClick={action}
          ></img>
        )}
        <div className={classes.Wrapper}>
          <span className={classes.Heading}>Menu</span>
          <Link className={classes.Title} to="/welcome">
            <span className={classes.Title}>Welcome</span>
          </Link>
          <Link className={classes.Title} to="/take-a-look">
            <span className={classes.Title}>Take a Look Around</span>
          </Link>
          <span
            className={classes.ActiveSubTitle}
            onClick={() => (window.location = "/frl_vr/vr_experience_1.html")}
          >{`${`>Scene 1`}`}</span>
          <span
            className={classes.ActiveSubTitle}
            onClick={() => (window.location = "/frl_vr/vr_experience_2.html")}
          >{`${`>Scene 2`}`}</span>
          <span
            onClick={() => {
              history.push("/induction");
              action();
              setStep({ id: false });
            }}
            className={classes.Title}
          >
            Life Saving Rules
          </span>

          <div className={classes.RulesContainer}>
            {LifeSavingRules.map((item, index) => (
              <span
                onClick={() => {
                  if (
                    (progressData && progressData?.section >= item.id) ||
                    item.id === 3
                  ) {
                    setStep({ id: item.id });
                    action();
                    history.push(`/induction/${item.id}`);
                  } else {
                  }
                }}
                className={
                  (progressData && progressData.section >= item.id) ||
                  item.id === 3
                    ? classes.ActiveSubTitle
                    : classes.DisabledSubTitle
                }
              >
                {index + 1}. {item.text}
              </span>
            ))}
          </div>
          <Link
            className={
              progressData && progressData.section < 8
                ? classes.DisabledTitle
                : classes.Title
            }
            to={progressData && progressData.section < 8 ? "#" : "/card-system"}
          >
            <span
              className={
                progressData && progressData.section < 8
                  ? classes.DisabledTitle
                  : classes.Title
              }
            >
              Card System
            </span>
          </Link>
          <Link
            className={
              progressData && progressData.section < 8
                ? classes.DisabledTitle
                : classes.Title
            }
            to={progressData && progressData.section < 8 ? "#" : "/site-rules"}
          >
            <span
              c
              className={
                progressData && progressData.section < 8
                  ? classes.DisabledTitle
                  : classes.Title
              }
            >
              Site Rules
            </span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (val) => dispatch(setSelectedStep(val)),
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    resetCurrentResult: (val) => dispatch(resetAssesmentResult(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)
);
