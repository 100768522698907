import React from "react";
import classes from "./checkbox.module.scss";
import CheckIcon from "../../assets/icons/check.svg";
import FailedIcon from "../../assets/icons/failed.svg";

const Checkbox = (props) => {
  return (
    <div
      style={{
        border: props.border && "3px solid #4DBD74",
        boxShadow:
          props.shadow &&
          "0px 2px 4px rgba(27, 28, 88, 0.2), 0px 20px 40px rgba(62, 65, 247, 0.13)",
        backgroundColor: props.color,
        width: props.width,
        height: props.height,
        borderRadius: props.borderRadius ? props.borderRadius : "22.5px",
      }}
      className={` ${classes.Checkbox} ${props.className && props.className}`}
      onClick={props.onClick}
    >
      {props.checked && (
        <img alt="Check Icon" className={classes.Image} src={CheckIcon} />
      )}
      {props.failed && (
        <img alt="Fail Icon" className={classes.Image} src={FailedIcon} />
      )}
    </div>
  );
};

export default Checkbox;
